<template>
  <div class="page invoices">
    <div class="container">
      <div class="top_line">
        <div class="text-left title_line flex w-full justify-between items-center">
          <h1 class="title text-2xl">Invoices</h1>

          <div class="title text-xl cursor-pointer green_text" @click="goBilling">
            View billing &xrarr;
          </div>
        </div>



      </div>
      <div class="content">
        <div class="addons bordered mt-8">
          <div class="head bg-grey-lighter px-5 py-5 flex text-left items-center justify-between gap-15">
            <div class="item w-1/3">
              Status
            </div>
            <div class="item w-1/3">
              Date
            </div>
            <div class="item w-1/3">
              Amount
            </div>
          </div>
          <div class="body px-5 text-left">
            <div v-for="(invoice, index) in all_invoices" :key="index">
              <div class="row py-5 pointer bordered_b flex" @click="getInvoice(invoice.invoice.id)">
                <div class="item green_text fw-b w-1/3 status flex items-center gap-5p">
                  <span>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" class="mr-2" height="20" width="20"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>
                  </span>
                  <span>
                    {{invoice.invoice.status}}
                  </span>
                </div>
                <div class="item w-1/3 px-2">
                  {{ invoice.invoice.date | moment('MM/DD/YYYY')}}
                </div>
                <div class="item w-1/3 px-3">
                  £ {{ invoice.invoice.amount_paid / 100 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "Invoices",
  data() {
    return {
      all_invoices: []
    }
  },
  methods: {
    ...mapMutations([
      'setLoading'
    ]),
    async getInvoices() {
      const response = await this.$http.getAuth(`${this.$http.apiUrl()}/subscriptions/invoices`);
      this.all_invoices = response.data.list;
    },
    async getInvoice(id) {
      this.setLoading(true)
      const response = await this.$http.getAuth(`${this.$http.apiUrl()}/subscriptions/invoices/${id}`);
      this.setLoading(false)
      window.open( response.data.download.download_url, '_blank' );
    },
    goBilling() {
      this.$router.push('/account/billing');
    },
  },
  async mounted() {
    await this.getInvoices()
  }
}
</script>

<style lang="scss" scoped>
.status {
  text-transform: capitalize;
}
.pointer {
  &:hover, &:active, &:focus {
    color: #1FBC2F;
  }
}
.item {
  font-size: 14px;
}
</style>
