import Toastify from 'toastify-js'

function infoMessage(text, cssClass = 'info') {
    Toastify({
        backgroundColor: '#ffffff',
        className: cssClass,
        text: text,
        duration: 3500,
        close: true,
        stopOnFocus: true,
        onClick: function () {
        } // Callback after click
    }).showToast();
}

export {infoMessage};
