<template>
  <ul v-if='sections' class='route_sections' >
    <li v-for='(section, index) of sections' :key='section.id' class=' py-5'>
      <ul v-if='section.actions'>
        <li class='' v-for='(action, i) of section.actions' :key='i'>
          <div class="info-block flex" v-if="i == 0">
            <!-- <div class="mark" :class='colors[index]' :style="{backgroundColor: colors[index]}"></div> -->
            <div class="data px-5 flex flex-col">
              <div class="flex" v-if="section.departure.place.address">
                      <span class="l-title">
                        Route Leg:
                      </span>
                <span>
                    {{index + 1}}
                      </span>
              </div>
              <div class="flex" v-if="section.departure.place.address">
                      <span class="l-title">
                      From:
                      </span>
                <span>
                      {{section.departure.place.address}}
                      </span>
              </div>
              <div class="flex" v-if="section.arrival.place.address">
                      <span class="l-title">
                      To:
                      </span>
                <span>
                      {{section.arrival.place.address}}
                      </span>
              </div>
              <div v-if="section.travelSummary.length">
                <div class="flex">
                        <span class="l-title">
                        Distance:
                        </span>
                  <span>
                        {{distanceToString(section.travelSummary.length)}}
                        </span>
                </div>
                <div class="flex">
                        <span class="l-title">
                        Duration:
                        </span>
                  <span>
                        {{secondsToDhms(section.travelSummary.duration)}}
                        </span>
                </div>
              </div>
            </div>

          </div>

          <div class="data">
            <div class="flex items-center gap-15 py-5 bordered_b">
              <div class="icon">
                      <span v-if="action.action === 'turn' && action.direction === 'left'">
                      <img src="@/assets/images/directions-svg/continue_left.svg" alt="logo">
                      </span>
                <span v-if="action.action === 'turn' && action.direction === 'right'">
                      <img src="@/assets/images/directions-svg/continue_right.svg" alt="logo">
                      </span>
                <span v-else-if="(action.action === 'roundaboutExit' || action.action === 'roundaboutPass') && action.direction === 'left'">
                      <img src="@/assets/images/directions-svg/roundabout.svg" alt="logo">
                      </span>
                <span v-else-if="(action.action === 'roundaboutExit' || action.action === 'roundaboutPass') && action.direction === 'right'">
                      <img class='rotate-180' src="@/assets/images/directions-svg/roundabout.svg" alt="logo">
                      </span>
                <span v-else-if="action.action === 'continue' || action.action === 'keep'">
                      <img src="@/assets/images/directions-svg/continue.svg" alt="logo">
                      </span>
              </div>
              <div class="flex gap-10p items-center direction_instruction">
                <span>{{i+1}}</span>
                <span>{{action.instruction}}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import {colors, distanceToString, secondsToDhms} from "@/services/helpers";

export default {
  name: 'RouteInstructions',
  methods: {secondsToDhms, distanceToString},
  computed: {
    colors() {
      return colors
    }
  },
  props: {
    sections: {
      type: Array,
    },
  }
}
</script>


<style scoped lang="scss">

</style>