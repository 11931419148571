<template>
  <div class="flex flex-col">
    <label :class="labelStyle">{{ label }} <span v-if="isRequired" class="required">*</span></label>

    <div class="relative">
      <input
          class="w-full"
          :class="inputStyle"
          type="text"
          @input="onSearchHandler"
          :id="input_id"
          :placeholder="placeholder ? placeholder : getSearchSelectedOption.name"
          @focus="onSearchHandler"
          v-model="countryName"
          @click="countryName = ''"
      >
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      </div>
    </div>

    <div v-if="filteredList.length" class="dropdown_block">
      <ul class="search-dropdown flex flex-col gap-5 py-5" v-if="showList">
        <li v-for="(option, idx) in filteredList" :key="idx">
          <a href="javascript:void(0)" @click="updateOption(option)">
            {{ option.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "LLRADropdownDop",
  data() {
    return {
      filteredList: [],
      showList: false,
      countryName: ''
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    input_id: {
      type: String,
      default: 'search_countries'
    },
    placeholder: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    defaultList: {
      type: Array
    },
    labelStyle: {
      type: String,
      default: ''
    },
    inputStyle: {
      type: String,
      default: ''
    },
  },
  methods: {
    ...mapMutations([
      'setSearchSelectedOption',
    ]),
    updateOption(option) {
      this.setSearchSelectedOption(option);
      this.countryName = option.name;
      this.showList = false;
    },
    onSearchHandler(ev) {
      this.showList = true
      const val = ev?.target?.value || '';
      const search = val.trim();
      const result = this.defaultList.filter(item => {
        if (item?.name?.indexOf(search) !== -1) {
          return item;
        }
      })
      this.filteredList = result;
    },
    clickOutsideHandler() {
      document.addEventListener('click', this.clickHandler);
    },
    clickHandler(event) {
      const { target } = event;
      const { $el } = this;

      if (!$el.contains(target)) {
        this.showList = false;
      }
    },
  },
  async mounted() {
    this.filteredList = this.defaultList;
    this.clickOutsideHandler();
  },
  computed: {
    ...mapGetters([
      'getSearchSelectedOption'
    ]),
  },
  with: {}
}
</script>

<style lang="scss" scoped>
.search-dropdown {
  border-width: 1px;
  border-color: #b8c2cc;
  padding: 10px;
  max-height: 300px;
  overflow: scroll;
}
.input_label {
  color: #22292f;
  font-size: 1em;
  font-weight: 600;
  span {
    color: #28a745;
  }
}
</style>
