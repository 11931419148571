<template>
  <div class="page routes">
    <div class="title_line flex flex-row justify-between items-center" >
      <h2>Routes</h2>
      <div class="inline-block flex w-2/3 justify-end button_group">
        <button class="text-white flex items-center button_text in_row_button full_green_button">
          <router-link to="/plan" class="flex items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4V19.9996" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M20 12L4.00038 12" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>

            <span class="h-full">
              Add Route
            </span>
          </router-link>
        </button>

        <button class="flex items-center gap-5p text-white flex items-center button_text in_row_button full_green_button"
        @click='upload_csv = !upload_csv'>
          <span class='rotate-180'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 10L12 15L17 10" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 15V3" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <span class="h-full whitespace-nowrap">
              Upload CSV
            </span>
        </button>

        <VueJsonToCsv
            v-if="checkedItems.length"
            :json-data="
                exportRoutes
              ">
          <button
              class="flex items-center  in_row_button square_button square_green_button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 10L12 15L17 10" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 15V3" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </VueJsonToCsv>
        <button
            v-if="checkedItems.length"
            @click="shareManyRoutes()"
            data-tip="Share route" class="btn btn-secondary text-primary flex justify-center items-center" currentitem="false">
          <span class="mr-1">
            <svg stroke="#1FBC2F" fill="#1FBC2F" stroke-width="0" viewBox="0 0 24 24" size="20" height="20" width="20"><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path></svg>
          </span>
        </button>

      </div>
    </div>
    <div class="filters px-4" v-if='!upload_csv'>
      <FiltersDop
          :names="names"
          :selected_name="selected_name"
          :items_count="routes_count"
          :rows_length="routes.length"
      ></FiltersDop>
    </div>
    <div class="routes_table fl_table mt-4 px-4" v-if='!upload_csv'>
      <div class="wrap" v-if="routes">
        <div class="header py-3 px-2 flex text-left w-full">
          <div class="name flex items-center gap-10p w-1/12">
            <span class="pt-2 px-2">
                <input type='checkbox' @click='checkAll()' v-model='isCheckAll'>
            </span>
            <div class="item flex items-center justify-between"
                 @click="sortItems('id')"
            >id
              <span class="sort"
                    :class="{
              'hidden': !sort_names.id,
              'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
            </div>
          </div>
          <div class="item flex items-center justify-between w-1/6"
               @click="sortItems('name')"
          >
            <span class="capitalize px-6">
              name
            </span>
            <span class="sort"
                  :class="{
              'hidden': !sort_names.name,
              'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>
          <div class="item flex items-center justify-between w-1/6"
               @click="sortItems('origin')"
          >
          <span class="capitalize">
            origin
          </span>
            <span class="sort"
                  :class="{
              'hidden': !sort_names.origin,
              'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>
          <div class="item flex items-center justify-between w-1/6"
               @click="sortItems('last_dropoff')"
          >
            <span class="capitalize">
              last dropoff
            </span>

            <span class="sort"
                  :class="{
              'hidden': !sort_names.last_dropoff,
              'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>
          <div class="item flex items-center justify-between w-1/12"
               @click="sortItems('driver_id')"
          >
            <span class="capitalize">
              driver name
            </span>

            <span class="sort"
                  :class="{
              'hidden': !sort_names.driver_id,
              'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>
          <div class="item flex items-center justify-between w-1/12"
               @click="sortItems('created_at')"
          >
            <span class="capitalize">
              Creation Date
            </span>

            <span class="sort"
                  :class="{
              'hidden': !sort_names.created_at,
              'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>
          <div class="item flex items-center justify-between w-1/12">
            <span class="capitalize">
              Start date
            </span>
          </div>
          <div class="item w-1/6"></div>
        </div>
        <div class="body py-2">
          <div v-for="(route, index) in routes" :key="index" class="py-5 px-2 flex text-left">
            <div class="flex items-center w-full">
              <div class="name flex items-center w-1/12 gap-10p">
                <span class="pt-2 px-2">
                  <input type='checkbox' v-bind:value='route.id' v-model='checkedItems' @change='updateCheckAll()'>
                </span>
                <a v-if='route.route_version === "v1"' :href="'/routes/' + route.id" class="flex items-center green_text gap-10p">
                  <span>
                    <svg width="16" height="16" fill="none">
                      <path d="M4 6.5a5.47 5.47 0 0 0 1 3.18l-.51.44-.9.26L0 13.46 1.8 15l3.6-3.08.3-.77.39-.33A5.5 5.5 0 1 0 4 6.5zm5.5-4a4 4 0 1 1 0 8 4 4 0 0 1 0-8z" fill="#1FBC2F"></path>
                    </svg>
                  </span>
                  <span class="green_text">
                    {{route.id}}
                  </span>
                </a>
                <a v-else :href="'/routes-v2/' + route.id" class="flex items-center green_text gap-10p">
                  <span>
                    <svg width="16" height="16" fill="none">
                      <path d="M4 6.5a5.47 5.47 0 0 0 1 3.18l-.51.44-.9.26L0 13.46 1.8 15l3.6-3.08.3-.77.39-.33A5.5 5.5 0 1 0 4 6.5zm5.5-4a4 4 0 1 1 0 8 4 4 0 0 1 0-8z" fill="#1FBC2F"></path>
                    </svg>
                  </span>
                  <span class="green_text">
                    {{route.id}}
                  </span>
                </a>
              </div>
              <div class="name w-1/6 px-6">
                {{route.name}}
              </div>
              <div class="name w-1/6">
                {{route.origin}}
              </div>
              <div class="name w-1/6">
                {{route.last_dropoff}}
              </div>
              <div class="name w-1/12">
                <span v-if="route.driver">{{route.driver.name}}</span>
              </div>
              <div class="name w-1/12">
                {{route.created_at | moment('DD-MM-YYYY')}}
              </div>
              <div class="name w-1/12">
                {{route.route_info.time_start | moment('DD-MM-YYYY')}}
              </div>
              <div class="actions w-1/6 flex items-center justify-around">
                <button
                          @click="openShareWindow(route.id)"
                          title="Share route" class="no-underline text-grey-dark hover:text-primary inline-flex regenerate-route-btn" tabindex="-1">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
                        </svg>
                      </button>
                <button v-if='route.route_version === "v2"'  @click="rePlanRoute(route.id)" class="my-tooltip no-underline text-grey-dark hover:text-primary inline-flex regenerate-route-btn" title="Duplicate route" tabindex="-1">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"></path>
                        </svg>
                        <span class="tooltiptext">replan route</span>
                </button>
                <button
                    @click="toDelete(route.id)"
                    class="no-underline text-grey-dark hover:text-primary inline-flex regenerate-route-btn">
                  <svg data-v-1739046c="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-1739046c="" d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"></path><path data-v-1739046c="" d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"></path><path data-v-1739046c="" d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path data-v-1739046c="" d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path data-v-1739046c="" d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ShareRoutes
          v-if="getShowShareRoutes && shareOneRoute"
          :ids="shareItem"
      />
      <ShareRoutes
          v-if="getShowShareRoutes && !shareOneRoute"
          :ids="checkedItems"
      />

      <DeleteModal
          :entity="'route'"
          v-on:delete-select="deleteRoute" v-on:delete-close="closeDelete" ref="delete_modal"/>

    </div>
    <div v-if='upload_csv'>
      <CSVUploadRoutes/>
    </div>
  </div>
</template>

<script>

import ShareRoutes from '../../components/ShareRoutes';
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "@/services/messages";
import DeleteModal from "@/components/DeleteModal";
import FiltersDop from "@/components/FiltersDop";
import VueJsonToCsv from 'vue-json-to-csv';
import CSVUploadRoutes from "@/components/CSVUploadRoutes";
export default {
  name: "Routes",
  data() {
    return {
      checkedItems: [],
      exportRoutes: [],
      shareItem: [],
      shareOneRoute: true,
      isCheckAll: false,
      upload_csv: false,
      routes: [],
      routes_count: null,
      route: null,
      id_to_delete: null,
      sort_names: {
        id: false,
        name: false,
        origin: false,
        last_dropoff: false,
        driver_id: false,
        created_at: false,
      },
      ascending: false,
      names:[
        {
          name: 'Route name',
          id: 'name'
        },
        {
          name: 'Route id',
          id: 'id'
        }
      ],
      selected_name: {name: 'Route name', id: 'name'}
    }
  },
  methods: {
    ...mapMutations([
      'setShowUpdatePassPopup',
      'setShowShareRoutes'
    ]),
    async resetPasswordPopup(route){
      this.route = route
      this.setShowUpdatePassPopup(true);
    },
    async openShareWindow(id) {
      this.shareItem = true;
      this.shareItem = [];
      this.shareItem.push(id);
      this.shareOneRoute = true;
      this.setShowShareRoutes(true)
    },
    shareManyRoutes() {
      this.shareItem = false;
      this.shareOneRoute = false;
      this.setShowShareRoutes(true)
    },
    checkAll: function(){
      this.isCheckAll = !this.isCheckAll;
      this.checkedItems = [];
      if(this.isCheckAll){
        let j = this;
        this.routes.forEach(route => {
          j.checkedItems.push(route.id);
        })
      }
    },
    updateCheckAll: function(){
      if(this.checkedItems.length == this.organisations.length){
        this.isCheckAll = true;
      }else{
        this.isCheckAll = false;
      }
    },
    async sortItems(name) {
      const j = this.sort_names;

      Object.keys(j).forEach(sort_name => {
        j[sort_name] = false;
      })
      this.ascending = !this.ascending;
      this.sort_names[name] = true
      await this.getRoutes(name)
    },
    toDelete(id){
      this.id_to_delete = id;
      this.$refs.delete_modal.show = true;

    },
    closeDelete() {
      this.delete_id = null;
    },
    async deleteRoute() {
      try {
        await this.$http.deleteAuth(`${this.$http.apiUrl()}/routes/${this.id_to_delete}`);
      } catch (e) {
        console.log(e);
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          `Route deleted`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );

      await this.getRoutes();
    },
    async copyRoute(id) {
      try {
        await this.$http.postAuth(`${this.$http.apiUrl()}/routes/copy/${id}`);
      } catch (e) {
        console.log(e);
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          `Route copied`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      await this.getRoutes();
    },

    rePlanRoute(id) {
      this.$router.push({name: 'HereRePlan', query: {replan_route_id: id}})
    },
    async getCheckedRoutes() {
      const response = await this.$http
          .postAuth(`${this.$http.apiUrl()}/routes/checked`, this.checkedItems);

      let j = this;
      this.exportRoutes = [];
      Object.keys(response.data).forEach(key => {
        j.exportRoutes.push(response.data[key])
      })
    },
    async getRoutes(sortBy = 'id') {
      this.routes = [];
      try {
        const requestData = {
          skip: this.getRowsToShift || 0,
          take: this.getItemsToShow || 5,
          sort: sortBy,
          search: this.getSearchItemsNames || '',
          ascending: this.ascending,
        }
        const response = await this.$http
            .getAuth(`${this.$http.apiUrl()}/routes?skip=${requestData.skip}&take=${requestData.take}&sort-by=${requestData.sort}&column=${requestData.column}&search=${requestData.search}&sort=${requestData.ascending}`);

        this.routes = response?.data?.data || null;
        this.routes_count = response?.data?.count || null;
        if (this.getShareNewRouteId) {
          await this.openShareWindow(this.getShareNewRouteId)
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  async mounted() {
    await this.getRoutes();
  },
  computed: {
    ...mapGetters([
      'getItemsToShow',
      'getSelectedFilterItem',
      'getRowsToShift',
      'getSearchItemsNames',
      'getShowShareRoutes',
      'getShareNewRouteId'
    ]),
  },
  components: {
    ShareRoutes,
    DeleteModal,
    FiltersDop,
    VueJsonToCsv,
    CSVUploadRoutes
  },
  watch: {
    getItemsToShow() {
      this.getRoutes()
    },
    checkedItems() {
      this.getCheckedRoutes()
    },
    getSelectedFilterItem() {
      this.getRoutes()
    },
    getRowsToShift() {
      this.getRoutes()
    },
    getSearchItemsNames() {
      this.getRoutes()
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  font-size: 14px;
  padding-right: 25px;
}
.name {
  font-size: 14px;
}
.green_text {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #1FBC2F !important;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
</style>

