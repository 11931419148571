<template>
  <div class="component share_routes modal-shadow saved_popup min-h-screen ">
    <div class="inner py-5 px-5" v-if="show">
      <div class="top_line py-5">
        <span class="text-right flex justify-end pointer" @click="close()">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-darkest-grey" height="1em" width="1em">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </span>
        <h2 class="popup_title text-2xl fw-b">Share Route</h2>
      </div>
      <div class="body">
        <div class="tabs">
          <div class="inner flex text-center">
            <div class="email w-1/2 m-auto text-center" @click="isEmail = true"
              :class="{'active': isEmail}"
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" class="mr-1" height="20" width="20"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path></svg>
            </div>
            <div class="sms w-1/2 m-auto" @click="isEmail = false"
                 :class="{'active': !isEmail}"
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" class="mr-1" height="20" width="20"><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z"></path></svg>
            </div>
          </div>
        </div>
        <div class="methods py-5 text-center m-auto flex justify-around">
          <div class="email flex flex-col text-left gap-5p w-full m-auto" v-if="isEmail">
            <label>Email</label>
            <input type="text" class="bordered input-reset p-2" placeholder="Enter email address" v-model="email">
            <div  v-if="errors.email.length > 0">
              <p class="error">
                {{ errors.email[0] }}
              </p>
            </div>
          </div>
          <div class="sms w-full m-auto flex-col gap-15" v-else>
            <div class="inner"  v-if="!forbid">
              <div class="input_group">
                <LLRADropdownDop
                    class="text-left"
                    :label="'Driver name'"
                    :defaultList="driversList"
                    :placeholder="'Search'"
                    :input_id="'driversList'"
                ></LLRADropdownDop>
              </div>
              <div class="input_group py-5">
                <input type="text" class="bordered input-reset p-2" placeholder="Phone" v-model="phone">
                <div  v-if="errors.phone.length > 0">
                  <p class="error">
                    {{ errors.phone[0] }}
                  </p>
                </div>
              </div>
            </div>
            <OutOfLimit
                v-else
                :items="'sms'"
                :itemsProps="'messages limit'"
                :count="allowedCount"
                :fullWidth="true"
            />
          </div>
        </div>
      </div>
      <div class="actions flex justify-end">
        <button
            :disabled="!allowShare && !isEmail"
            @click="share()"
            class="ml-auto mt-8 btn btn-primary text-icon-container text-center">
          <svg v-if="(isEmail && validEmail) || (allowShare && !isEmail)" stroke="#1FBC2F" fill="#1FBC2F" stroke-width="0" viewBox="0 0 24 24" size="20" height="20" width="20"><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path></svg>
          <svg v-else stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" height="20" width="20"><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path></svg>
        </button>
      </div>
    </div>
    <div v-else class="inner success py-5 px-5">
        <div class="bg-white rounded py-8 relative flex flex-col">
          <div class="cursor-pointer flex justify-end pin-r pint-t mr-5 mb-5" style="top: 20px;"
            @click="setShowShareRoutes(false)"
          >
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-darkest-grey" height="1em" width="1em">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </div>
          <div class="flex justify-between text-center m-auto items-center py-3 mb-5">
            <svg stroke="#1FBC2F" fill="#1FBC2F" stroke-width="0" viewBox="0 0 24 24" class="bg-white text-green mr-1" height="1em" width="1em">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
            </svg>
            <span class="title text-2xl ">
              Route(s) was sent successfully!
            </span>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "@/services/messages";
import {validateEmail} from "@/services/helpers";
import LLRADropdownDop from "@/components/LLRADropdownDop";
import OutOfLimit from "@/components/OutOfLimit";

export default {
  name: "ShareRoutes",
  props: {
    ids: {
      type: Array
    }
  },
  data() {
    return {
      forbid: false,
      allowedCount: 0,
      allowShare: false,
      isEmail: true,
      validEmail: false,
      email: null,
      phone: null,
      show: true,
      errors: {
        email: [],
        phone: [],
      },
      driversList: []
    }
  },
  methods: {
    ...mapMutations([
        'setShowShareRoutes',
        'setLoading'
    ]),
    async close () {
     await this.setShowShareRoutes(false);
    },
    errorsHandler(errors) {
      const j = this.errors;
      errors.forEach(function(error){
        if (error.split(' ')[0]) {
          j[error.split(' ')[0]].push(error);
        }
      })
      if (this.errors.email.length && this.errors.email[0] == 'email must be an email') {
        this.errors.email[0] = 'Enter a valid email address';
      }
    },
    async drivers() {

      const isForbid = await await this.$http.getAuth(`${this.$http.apiUrl()}/routes/share/forbid`);
      if (isForbid?.data?.forbid) {
        this.forbid = true;
        this.allowedCount = isForbid.data.count
      }

      const drivers_list = await this.$http.getAuth(`${this.$http.apiUrl()}/users/drivers`);
      if (drivers_list) {
        this.driversList = drivers_list.data.drivers.map(driver => {
          return {
            id: driver.id,
            name: driver.name,
            phone: driver.mobile_phone
          }
        })
      }
    },
    async share () {
      this.setLoading(true)
      try {
        for (let error in this.errors) {
          if (error.length > 0 && error != null) {
            this.errors[error] = []
          }
        }
        let errors = null;
        await this.$http.postAuth(`${this.$http.apiUrl()}/routes/share`, {
          ids: this.ids,
          email: this.email || null,
          phone: this.phone || null
        }).catch(err => {
          errors = err.response?.data?.message;
          if (typeof errors === 'string') {
            return infoMessage(errors || 'Oops. Something went wrong...', 'error')
          }

          this.setLoading(false)
          return this.errorsHandler(errors);
        });
        if (!this.errors.email.length && !this.errors.phone.length) {
          this.show = false;
        }
        this.setLoading(false)
      } catch (e) {
        console.error(e);
      }
    }
  },
  async mounted() {
    await this.drivers();
  },
  computed: {
    ...mapGetters([
      'getSearchSelectedOption'
    ]),
  },
  watch: {
    email(val) {
      if (validateEmail(val)) {
        this.validEmail = true
      } else {
        this.validEmail = false
      }
    },
    isEmail(val) {
      if (val) {
        this.phone = null
      } else {
        this.email = null
      }
    },
    getSearchSelectedOption(val) {
      if (val.phone) {
        this.phone = val.phone;
        this.allowShare = true;
      } else {
        this.phone = null;
        this.allowShare = false;
        return infoMessage(`No phone assigned for ${val.name}`, 'error')
      }
    }
  },
  components: {
    LLRADropdownDop,
    OutOfLimit
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  .inner {
    max-width: 200px;
    margin: auto;
    &.success {
      max-width: 400px;
    }
  }
}
.tabs {
  .inner {
    svg {
      text-align: center;
      margin: auto;
    }
  }
  .active {
    background: transparent;
    border-bottom: 3px #1fbc2f solid;
  }
}
.methods {
  max-width: 50%;
}
</style>
