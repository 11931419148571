<script src="../../services/http_client.js"></script>
<template>
  <div class="page">
    <div class="logo_icon bordered_b flex items-center text-left flex-start">
      <div class="content">
        <div class="logo w-full py-10 ">
          <img :src="logo_url" alt="logo">
        </div>
      </div>
    </div>
    <div class="content">
      <div class="bordered_b">
        <span class="back flex items-center text-grey-darker pt-2">
              <span>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" class="mr-1" height="20" width="20"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
              </span>
              <router-link
                  :to="{ name: 'Register'}">Select Plan
              </router-link>
        </span>
        <h2 class="big-title font-bold text-left py-10 text-2xl">
          Register your company
        </h2>
      </div>
      <div class="register-form" v-if="plan">

        <div class="_form py-8">
          <div class="form_title text-left flex pb-15">
            <div class="group_title fw-b w-1/4">
              Selected plan
            </div>
            <div class="group_inner w-3/4 text-primary-dark fw-b">
              {{ plan.description }} - {{ plan.price/100 }} / {{ plan.billing_period }}
            </div>
          </div>
          <form @submit.prevent="" class="flex text-left flex-col gap-30">
            <div class="top_group flex">
              <div class="group_title fw-b w-1/4">
                <h2 class="big-title-no-padding">Account details</h2>
              </div>
              <div class="group_inner w-3/4 flex  flex-col gap-30">
                <div class="inner_group flex gap-15">
                  <div class="input-group flex flex-col text-left">
                    <label slot="label" for="company_name" class="input-label ">Organisation name <span class="required">*</span></label>
                    <input v-model="user.company_name" id="company_name" type="text">
                    <div  v-if="errors.company_name.length > 0">
                      <p class="error">
                        {{ errors.company_name[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="input-group flex flex-col text-left">
                    <label for="username">Username <span class="required">*</span></label>
                    <input v-model="user.username" id="username" type="text">
                    <div  v-if="errors.username.length > 0">
                      <p class="error">
                        {{ errors.username[0] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="inner_group flex gap-15">
                  <div class="input-group flex flex-col text-left">
                    <label for="password">Password <span class="required">*</span></label>
                    <input v-model="user.password_digest"
                           id="password" type="password">
                    <div  v-if="errors.password_digest.length > 0">
                      <p class="error">
                        {{ errors.password_digest[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="input-group flex flex-col text-left">
                    <label for="confirm_password">Confirm Password <span class="required">*</span></label>
                    <input v-model="user.password_confirm"
                           id="confirm_password" type="password">
                    <div  v-if="errors.password_confirm.length > 0">
                      <p class="error">
                        {{ errors.password_confirm[0] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="inner_group flex gap-15">
                  <div class="input-group flex flex-col text-left">
                    <label for="email">Email <span class="required">*</span></label>
                    <input v-model="user.email" id="email" type="text">
                    <div  v-if="errors.email.length > 0">
                      <p class="error">
                        {{ errors.email[0] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="middle_group flex">
              <div class="group_title fw-b w-1/4">
                <h2 class="big-title-no-padding">Address details</h2>
              </div>
              <div class="group_inner w-3/4 flex flex-col gap-30">
                <div class="inner_group flex gap-15">
                  <div class="input-group flex flex-col w-full">
                    <label for="contact_name">Contact name <span class="required">*</span></label>
                    <input v-model="user.contact_name" id="contact_name" type="text">
                    <div  v-if="errors.contact_name.length > 0">
                      <p class="error">
                        {{ errors.contact_name[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="input-group flex flex-col w-full">
                    <label for="phone">Phone <span class="required">*</span></label>
                    <input v-model="user.phone" id="phone" type="text">
                    <div  v-if="errors.phone.length > 0">
                      <p class="error">
                        {{ errors.phone[0] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="inner_group flex gap-15">
                  <div class="input-group flex flex-col w-full">
                    <label for="address_1">Address 1 <span class="required">*</span></label>
                    <input v-model="user.address_1" id="address_1" type="text">
                    <div  v-if="errors.address_1.length > 0">
                      <p class="error">
                        {{ errors.address_1[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="input-group flex flex-col w-full">
                    <label for="address_2">Address 2</label>
                    <input v-model="user.address_2" id="address_2" type="text">
                    <div  v-if="errors.address_2.length > 0">
                      <p class="error">
                        {{ errors.address_2[0] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="inner_group flex gap-15">
                  <div class="input-group flex flex-col w-full">
                    <label for="address_1">City/Town <span class="required">*</span></label>
                    <input v-model="user.city" id="city" type="text">
                    <div  v-if="errors.city.length > 0">
                      <p class="error">
                        {{ errors.city[0] }}
                      </p>
                    </div>
                  </div>
                  <div class="input-group flex flex-col w-full">
                    <label for="postcode">Postcode <span class="required">*</span></label>
                    <input v-model="user.postcode" id="postcode" type="text">
                    <div  v-if="errors.postcode.length > 0">
                      <p class="error">
                        {{ errors.postcode[0] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="inner_group flex gap-15">
                  <div class="input-group flex flex-col w-full">

                    <div ref="search_countries" class="w-full">
                      <LLRADropdown
                          :label="'Country'"
                          :defaultList="countriesList"
                      ></LLRADropdown>
                    </div>
                    <div  v-if="errors.country.length > 0">
                      <p class="error">
                        {{ errors.country[0] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom_group flex">
            <div class="group_title fw-b w-1/4">
              <h2 class="big-title-no-padding">Fleet size</h2>
            </div>
            <div class="group_inner w-3/4 flex flex-col">
              <div class="input_group">
                <div class="input-group">
                  <dropdown
                      id="fleet_size"
                      class="pseudo-input text-left w-full"
                      :options="fleet_sizes"
                      :selected="fleet_sizes[0]"
                      v-on:updateOption="selectFleetSize"
                  />
                </div>
              </div>
              <div class="input_group pt-10">
                <div class="input-group flex w-full gap-15">
                  <span class="flex height-20 items-center">
                    <input v-model="user.autorenewal" type='checkbox'><span class="required">&nbsp;*</span>
                  </span>
                  <span class="w-11/12">
                    By ticking this box and clicking the Register button, I give permission to Pro Mapping Software Ltd. to automatically charge my credit/debit card monthly/annually to renew my subscription. I can turn off auto renew at any time.
                  </span>
                </div>
                <div  v-if="errors.autorenewal.length > 0">
                  <p class="error">
                    {{ errors.autorenewal[0] }}
                  </p>
                </div>
              </div>
              <div class="input_group">
                <div class="input-group flex pt-2 w-full  gap-15">
                  <span class="flex height-20 items-center">
                    <input v-model="user.marketing_email" type='checkbox'> <span class="required">&nbsp;&nbsp; </span>
                  </span>
                  <span class="w-11/12">
                    Keep me updated on new features, promotions, services and industry updates please!
                  </span>
                </div>
                <div  v-if="errors.marketing_email.length > 0">
                  <p class="error">
                    {{ errors.marketing_email[0] }}
                  </p>
                </div>
              </div>
              <div class="input_group">
                <div class="input-group flex pt-2 w-full gap-15">
                  <span class="flex height-20 items-center">
                    <input v-model="user.privacy" type='checkbox'> <span class="required">&nbsp;*</span>
                  </span>
                  <span class="w-11/12">
                    <span>I confirm that I have read and agree to the
                      <router-link :to="{path: 'https://lorryroute.com/legal/terms-and-conditions'}" class="text-grey-dark" target="_blank">Terms &amp; Conditions</router-link>,
                      <router-link :to="{path: 'https://lorryroute.com/legal/terms-of-service'}" class="text-grey-dark" target="_blank">Terms of service</router-link> and
                      <router-link :to="{path: 'https://lorryroute.com/legal/privacy'}" class="text-grey-dark" target="_blank">Privacy policy</router-link>
                    </span>
                  </span>
                </div>
                <div  v-if="errors.privacy.length > 0">
                  <p class="error">
                    {{ errors.privacy[0] }}
                  </p>
                </div>
              </div>
            </div>
            </div>
            <div class="actions_group flex flex-row justify-end gap-15">
              <button class="green-white px-4 py-3" @click="createUser">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dropdown from 'vue-dropdowns';
import LLRADropdown from "../../components/LLRADropdown";
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "../../../../admin/src/services/messages";
export default {
  name: "RegisterForm",
  props: {
    plan: null
  },
  data() {
    return {
      logo_url: `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).icon_url || null,
      user: {
        company_name: '',
        email: '',
        contact_name: '',
        address_1: '',
        address_2: '',
        city: '',
        postcode: '',
        country: 'GB',
        phone: '',
        username: '',
        password_digest: '',
        password_confirm: '',
        role: 4,
        autorenewal: null,
        marketing_email: null,
        privacy: null,
        fleet_size: '1-5'
      },
      errors: {
        company_name: [],
        email: [],
        contact_name: [],
        address_1: [],
        address_2: [],
        city: [],
        postcode: [],
        country: [],
        phone: [],
        username: [],
        password_digest: [],
        password_confirm: [],
        role: [],
        autorenewal: [],
        marketing_email: [],
        privacy: [],
        fleet_size: []
      },
      showCountries: true,
      countriesList: [],
      filteredList: [],
      fleet_sizes:[
        {
          name: '1-5',
          id: '1-5'
        },
        {
          name: '5-10',
          id: '5-10'
        },
        {
          name: '10-15',
          id: '10-15'
        },
        {
          name: '15-20',
          id: '15-20'
        },
        {
          name: '20-50',
          id: '20-50'
        },
        {
          name: '50+',
          id: '50+'
        }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setSearchSelectedOption',
      'loginUser'
    ]),
    selectFleetSize(val) {
      this.fleet_size = val.id;
    },
    onSearchHandler(ev) {
      const val = ev?.target?.value || '';
      const search = val.trim();
      const result = this.countriesList.filter(country => {
        if (country?.name?.indexOf(search) !== -1) {
          return country;
        }
      })
      this.filteredList = result;
    },
    async createUser() {
      for (let error in this.errors) {
        if (error.length > 0) {
          this.errors[error] = []
        }
      }
      const {user} = this;
      const request_data = {...user};
      const response = await this.$http.post(`${this.$http.apiUrl()}/users/create`, request_data).catch(err => {
       const errors = err.response?.data?.message;
        if (typeof errors === 'string') {
          return infoMessage(errors || 'Oops. Something went wrong...', 'error')
        }
        return this.errorsHandler(errors);
      });

      if(response && response?.data?.user){
        await this.processLogin(request_data)
      }
    },
    async processLogin(request_data) {
      const request = {username:request_data.username, password:request_data.password_digest};
      const response = await this.$http.post(`${this.$http.apiUrl()}/auth/login`, request).catch(err => {
        const errors = err.response?.data?.message;
        if (typeof errors === 'string') {
          return infoMessage(errors || 'Oops. Something went wrong...', 'error')
        }
        return this.errorsHandler(errors);
      });
      const {user, access_token} = response?.data || {};
      console.log(response?.data)
      if (user && access_token) {
        await this.loginUser({user, access_token});
        try {
          infoMessage(`User ${user.username} was created`, 'success')
          await this.$router.push({name: 'ChangePlan'});
        } catch (e) {
          infoMessage(e || 'Oops. Something went wrong...')
        }
      } else {
        infoMessage('Oops. Something went wrong...')
      }
    },
    errorsHandler(errors){
      const j = this.errors;
      errors.forEach(function(error){
        if (error.split(' ')[0]) {
          if (error.split(' ')[0] === 'name') {
            j.contact_name.push(error);
          } else {
            j[error.split(' ')[0]].push(error.replaceAll('_', ' '));
          }
        }
      })
    },
    async getCountries() {
      try {
        const url = 'https://restcountries.com/v3.1/all?fields=name,cca2';
        const result = await this.$http.get(url);
        let countries = []
        result.data.forEach((country) => {
          countries.push({
            name: country.name.common,
            id: country.cca2
          })
        })
        const sorted = countries.slice().sort(function(a, b){
          return (a.name > b.name) ? 1 : -1;
        });
        this.countriesList = sorted;
        this.filteredList = sorted;
      } catch (e) {
        console.log(e);
        infoMessage('Oops. Something went wrong...')
      }
    },
    checkPlan(){
      if (!this.plan) {
        this.$router.push({name: 'Register'})
      }
    }
  },
  async mounted() {
    await this.getCountries();
    await this.checkPlan();
  },
  components: {
    LLRADropdown,
    dropdown
  },
  computed: {
    ...mapGetters([
      'getSearchSelectedOption'
    ]),
  },
}
</script>

<style lang="scss" scoped>
.title_line {
  padding-bottom: 15px;
}
.input-group {
  width: 100%;
}
.pseudo-input{
  margin: 0;
  min-height: 2.5rem;
  font-size: .875rem;
  color: #3d4852;
  border-width: 1px;
  border-color: #b8c2cc;
  border-radius: 0.25rem;
  background-color: #fff;
  padding: 0;
}
</style>
