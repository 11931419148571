<template>
  <div class="inline-block relative w-full custom_selected_body">
    <input
        type="text"
        :placeholder="placeholder"
        @keyup.enter="enterSelect"
        @click="toggleShow"
        v-model="search"
        class="block w-full bg-white border border-gray-300 px-4 py-2 pr-8 rounded-2xl leading-tight focus:outline-none focus:shadow-outline">

    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-green-500">
      <svg v-if="show" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
    </div>

    <div class="absolute w-full border bg-white border-gray-300 px-3 py-2 rounded drop_body_custom_select" v-if="show">
      <div
          @click="selectedItem(item)"
          class="hover:text-green-500 pointer"
          v-for="(item, index) in view_array"
          :key="'custom-select-input-item-' + index">
        <span v-if="view && view != ''">{{ item[view] }}</span>
        <span v-else>{{ item }}</span>
      </div>
    </div>
    <div class="blocked" v-if="show" @click="show = false" :style="'left: -' + this_left + 'px; top: -' + this_top +'px; min-width: calc(100vw - ' + this_left + 'px);'"></div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    array: {
      default: [],
    },
    view: {
      type: String,
      default: '',
    },
    return: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select...'
    },
  },
  data() {
    return {
      search: null,
      show: false,
      view_array: [],
      result_this: null,
      this_left: 0,
      this_top: 0,
    }
  },
  methods: {
    enterSelect() {
      if (this.view_array.length === 1) {
        this.result_this = this.view_array.pop();
        this.search = this.view && this.view != '' ? this.result_this[this.view] : this.result_this;
        this.show = false;
        this.$emit('selected-item-in-custom-select', this.result_this);
      }
    },
    async getPosition() {
      let c = window.document.querySelector('.custom_selected_body').getBoundingClientRect(),
          scrolltop = document.body.scrollTop + c.top,
          scrollleft = document.body.scrollLeft + c.left;
      return {
        top: scrolltop,
        left: scrollleft
      };
    },
    async toggleShow() {
      let position = await this.getPosition();
      this.this_left = position.left;
      this.this_top = position.top;
      this.show = true;
    },
    selectedItem(item) {
      this.search = this.view && this.view != '' ? item[this.view] : item;
      this.show = false;
      this.result_this = item;
      this.$emit('selected-item-in-custom-select', this.result_this);
    },
    testStr(val, str, res_array, key) {
      let test_str = str.toLowerCase();

      if (test_str.includes(val)) {
        res_array.push(this.array[key]);
      }
      return res_array;
    },
    async filteredArray(val) {
      let res_array = [];
      if (this.view && this.view != '') {
        for (let key in this.array) {
          let str = this.array[key][this.view];
          res_array = this.testStr(val, str, res_array, key);
        }
      }
      if (!this.view || this.view == '') {
        for (let key in this.array) {
          let str = this.array[key];
          res_array = this.testStr(val, str, res_array);
        }
      }
      return res_array;
    },
  },
  watch: {
    async search(val) {
      this.view_array = await this.filteredArray(val);
    }
  },
  mounted() {
    this.view_array = this.array;
  }
}
</script>
<style style lang="scss" scoped>
.custom_selected_body {
  z-index: 484;
}
.drop_body_custom_select {
  z-index: 486;
  max-height: 120px;
  overflow: scroll;
}
.blocked {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  z-index: 485;
}
</style>
