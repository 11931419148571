<template>
  <div >
    <div class='upload-csv  pt-10'>
      <h3 class='title font-bold bold pt-10'>Upload routes CSV</h3>
      <input
          accept=".csv"
          class='upload-input file pt-10'
          ref='uploadCSVFile' type='file' @change="fileUploaded()">
      <div v-if='file_names_exceptions && file_names_exceptions.length && allow_to_check_columns'>
        <h2 class='font-bold error_title mt-10 mb-10'>Please select correct columns names</h2>
        <div class='exceptions w_80'>
          <div class='inner btn_danger mb-2 p-2 flex-row flex' v-for='(column) in file_names_exceptions' :key='column'>
            <div class='w-1/2'>
              <select @change='selectNewName($event.target.value, column)'>
                <option value="" selected disabled hidden>Choose here</option>
                <option v-for='column_name in columns_filtered_names' :key='column_name'>
                  {{column_name}}
                </option>
              </select>
            </div>
            <div class='w-1/2'>
              <div class='inner'>
                {{column}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
        class='btn green-white mt-10'
        v-if='allow_to_validate && !allow_to_upload'
        @click='validateCSV'>Check uploaded routes</button>

    <div v-if='wrong_postcodes_list && wrong_postcodes_list.length'>
      <h2>Please remove or change next postcodes in your file</h2>
      <ol>
        <li v-for='item in wrong_postcodes_list' :key='item'>{{item}}</li>
      </ol>
    </div>

    <div v-if='wrong_dates && wrong_dates.length'>
      <h2>Please remove or change next dates in your file</h2>
      <ol>
        <li v-for='item in wrong_dates' :key='item'>{{item}}</li>
      </ol>
    </div>

    <div v-if='wrong_time && wrong_time.length'>
      <h2>Please remove or change next times in your file</h2>
      <ol>
        <li v-for='item in wrong_time' :key='item'>{{item}}</li>
      </ol>
    </div>
    <div
    >
      <button

          class='btn green-white mt-10'
          v-if='allow_to_upload && !upload_started'
          @click.once='uploadCSV'>Upload routes</button>
      <button

          class='btn green-white mt-10'
          v-if='upload_started'
          @click='show_upload_popup = true'
      >Complete</button>
    </div>
    <UploadRoutesInfoModal
      v-if='show_upload_popup'
      v-on:close-upload-routes-modal='show_upload_popup = false'
    />
  </div>

</template>

<script>
import parseFile from "vue-xls-csv-parser/src/parser";
import CustomSelect from "@/components/customel/CustomSelect";
import {infoMessage} from "@/services/messages";
import {validateDate, validateHhMm} from "@/services/helpers";
import moment from "moment";
import structuredClone from "@ungap/structured-clone";
import {mapMutations} from "vuex";
import UploadRoutesInfoModal from "@/components/UploadRoutesInfoModal";

export default {
  name: "CSVUploadRoutes",
  comments: {
    CustomSelect
  },
  data() {
    return {
      file_item: [],
      wrong_postcodes_list: [],
      wrong_dates: [],
      wrong_time: [],
      file_names_exceptions: [],
      file_names_exceptions_clone: [],
      allowed_file_names: [],
      file_json: null,
      allow_to_validate: false,
      allow_to_upload: false,
      allow_to_check_columns: false,
      show_upload_popup: false,
      upload_started: false,
      date_columns: [
        "start date",
        "end date",
      ],
      time_columns: [
        "start time",
        "end time",
      ],
      postcodes_columns: [
        "origin",
        "destination 1",
        "destination 2",
        "destination 3",
        "destination 4",
        "destination 5",
        "destination 6",
        "destination 7",
        "destination 8",
        "destination 9",
        "destination 10",
        "destination 11",
        "destination 12",
        "destination 13",
        "destination 14",
      ],
      columns_names: [
          "route name",
          "origin",
          "destination 1",
          "destination 2",
          "destination 3",
          "destination 4",
          "destination 5",
          "destination 6",
          "destination 7",
          "destination 8",
          "destination 9",
          "destination 10",
          "destination 11",
          "destination 12",
          "destination 13",
          "destination 14",
          "vehicle height",
          "vehicle width",
          "vehicle length",
          "vehicle weight",
          "vehicle 1",
          "vehicle 2",
          "vehicle 3",
          "vehicle 4",
          "vehicle 5",
          "vehicle 6",
          "vehicle 7",
          "vehicle 8",
          "vehicle 9",
          "vehicle 10",
          "trailer height",
          "trailer width",
          "trailer length",
          "trailer weight",
          "trailer 1",
          "trailer 2",
          "trailer 3",
          "trailer 4",
          "trailer 5",
          "trailer 6",
          "trailer 7",
          "trailer 8",
          "trailer 9",
          "trailer 10",
          "start date",
          "start time",
          "end date",
          "end time",
          "llcs permit",
        ],
      columns_filtered_names: [],
    }
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'setUpoadedRoutesFile'
    ]),
    async uploadCSV() {
      const obj = {};

      this.file_json.forEach(({ name, data }) => {
        obj[name] = data;
      });

      const result = obj[this.file_json[0].name].map((_, index) => {
        const newObj = {};
        Object.entries(obj).forEach(([name, data]) => {
          newObj[name] = data[index];
        });
        return newObj;
      });

      this.upload_started = true;

      this.setLoading(true);
      try {
        await this.setUpoadedRoutesFile(result);
      } catch (e) {
        console.log(e);

        this.setLoading(false);
      }

      this.setLoading(false);
    },
    async validateCSV() {
      this.setLoading(true);
      this.allow_to_upload = true;
        for await (const [i1, item] of this.file_json.entries()) {
         for await (let [i2, data] of item?.data.entries()) {
           if (this.time_columns.includes(item?.name)) {
              const isValid = validateHhMm(data);
              if (!isValid) {
                this.wrong_time.push(data);
                this.allow_to_upload = false;
                this.allow_to_check_columns = false;
              }
           }
           if (this.date_columns.includes(item?.name)) {
             data = moment(data).format('YYYY-MM-DD');
              const isValid = validateDate(data);
              if (!isValid) {
                this.wrong_dates.push(data);
                this.allow_to_upload = false;
                this.allow_to_check_columns = false;
              }
           }
           if (this.postcodes_columns.includes(item?.name) && data) {
             const request_data = {string: data};
             const response = await this.$http.postAuth(`${this.$http.apiUrl()}/subscriptions/search-points-csv`, request_data).catch(err => {
               const errors = err.response?.data?.message;
               if (typeof errors === 'string') {
                 return infoMessage(errors || 'Oops. Something went wrong...', 'error')
               }
               return this.errorsHandler(errors);
             });
            if (!response?.data[0]?.Location){
              this.wrong_postcodes_list.push(data);
              this.allow_to_upload = false;
              this.allow_to_check_columns = false;
            } else {
              const address = response?.data[0]?.Location?.Address?.PostalCode + ', ' + (response?.data[0]?.Location?.Address?.District || response?.data[0]?.Location?.Address?.City || response?.data[0]?.Location?.Address?.County || null);
              data = `${address}||${response?.data[0]?.Location?.DisplayPosition?.Latitude}||${response?.data[0]?.Location?.DisplayPosition?.Longitude}`;
              this.file_json[i1].data[i2] = data;
            }
          }
        }
        }
      this.setLoading(false);
      },
    async selectNewName(val, column) {
      if (this.file_names_exceptions.length && !this.file_names_exceptions_clone.length) {
        this.file_names_exceptions_clone = structuredClone(this.file_names_exceptions);
      }
      if (this.file_names_exceptions_clone.length) {
        this.file_names_exceptions_clone = this.file_names_exceptions_clone.filter((item) => item !== column);
      }
      if (!this.file_names_exceptions_clone.length) {
        this.allow_to_validate = true;
      }
      for await (const item of this.file_json) {
        if (column === item?.name) {
          item.name = val
        }
      }
    },
    async fileUploaded() {
      this.allow_to_validate = true;
      this.allow_to_check_columns = true;
      const file = this.$refs.uploadCSVFile.files[0];
     await parseFile(file, 'en').then((results) => {
        this.error = null;
        this.file_json = results;
      }).catch((error) => {
        this.error = error;
        this.file = null;
      });
     let filtered = [];
     if (this.file_json) {
       for await (const item of this.file_json) {
         if (!this.columns_names.includes(item?.name)) {
           this.file_names_exceptions.push(item?.name)
           this.allow_to_validate = false;
         } else {
           filtered.push(item?.name)
         }
       }
       this.columns_filtered_names = this.columns_names.filter(function(column) {
         if (!filtered.includes(column)) {
           return column
         }
       })
     }
    },
  },
  computed: {
  },
  components: {
    UploadRoutesInfoModal,
  },
}
</script>

<style scoped>

</style>