<template>
  <div class="page">
    <div class="container success"><div class="mx-auto" style="max-width: 546px;">
      <div class="bg-white flex flex-col rounded border boder-grey-dark shadow px-10 py-12 mb-6">
        <div class="logo-wrapper mb-5 flex justify-center">
          <img v-if="logo_url" :src="logo_url" alt="logo">
          <img v-else src="@/assets/images/logo.png" alt="logo">
        </div>
        <h1 class="font-medium text-2xl text-center mb-6 leading-tight">Thank You! It's good to have you onboard!</h1>
        <p class="text-center mb-8">Your plan has been updated. Start planning your next journey with compliant routes.</p>
        <router-link :to="{name: 'Home'}" class="btn green-white px-6">Go back to Dashboard</router-link>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessPage",
  data() {
    return {
      logo_url: null,
    }
  },
  methods: {
    logoUrl() {
      this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).logo_url || null
    },
  },
  async mounted() {
    await this.logoUrl();
  },
}
</script>

<style lang="scss" scoped>
.success {
  margin-top: 150px;
}
</style>
