<template>
  <div class="flex flex-col">
    <label>{{ label }} <span v-if="isRequired" class="required">*</span></label>
    <input
        class="w-full"
        type="text"
        @input="onSearchHandler"
        id="search_countries"
          :placeholder="placeholder ? placeholder : getSearchSelectedOption.name"
        @focus="onSearchHandler"
        v-model="countryName"
        @click="countryName = ''"
    >
    <div v-if="filteredList.length" class="dropdown_block">
      <ul class="search-dropdown flex flex-col gap-5 py-5" v-if="showList">
        <li v-for="(option, idx) in filteredList" :key="idx">
          <a href="javascript:void(0)" @click="updateOption(option)">
            {{ option.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "LLRADropdown",
  data() {
    return {
      filteredList: [],
      showList: false,
      countryName: ''
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    defaultList: {
      type: Array
    },
  },
  methods: {
    ...mapMutations([
      'setSearchSelectedOption',
    ]),
    updateOption(option) {
      this.setSearchSelectedOption(option);
      this.countryName = option.name;
      this.showList = false;
    },
    onSearchHandler(ev) {
      this.showList = true
      const val = ev?.target?.value || '';
      const search = val.trim();
      const result = this.defaultList.filter(item => {
        if (item?.name?.indexOf(search) !== -1) {
          return item;
        }
      })
      this.filteredList = result;
    },
    clickOutsideHandler() {
      document.addEventListener('click', this.clickHandler);
    },
    clickHandler(event) {
      const { target } = event;
      const { $el } = this;

      if (!$el.contains(target)) {
        this.showList = false;
      }
    },
  },
  async mounted() {
    this.filteredList = this.defaultList;
    this.clickOutsideHandler()
  },
  computed: {
    ...mapGetters([
      'getSearchSelectedOption'
    ]),
  },
}
</script>

<style lang="scss" scoped>
.search-dropdown {
  border-width: 1px;
  border-color: #b8c2cc;
  padding: 10px;
  max-height: 300px;
  overflow: scroll;
}
</style>
