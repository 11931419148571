<script>
export default {
  name: 'ZoomMap',
  methods: {
    scaleMap(zoomIn = false) {
      this.$emit('scale-map', zoomIn);
    }
  }
}
</script>

<template>
  <div class='zoom'>
    <div class='flex flex-col'>
      <div class='plus item' @click='scaleMap(true)'>+</div>
      <div class='minus item' @click='scaleMap()'>-</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.zoom {
  background: #fff;
  border-radius: 3px;
  line-height: 0;
  .item {
    padding: 15px 7px;
    cursor: pointer;
    font-size: 30px;
    line-height: 0;
    border: 0.5px solid #f2f2f2;
    &:hover {
      background: #f2f2f2;
    }
  }
}
</style>