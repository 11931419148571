<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col">
    <div class="inner">
      <div class="content">
        <div class="w-full flex justify-between modal_header p-6 title_line">
          <div class="modal_title mt-2">
            Upload in progress
          </div>

          <button class="x_btn" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>

        <div class="modal_body flex flex-col content-center mt-6">
          <div class="w-full px-6">
            <label for="vehicle_group_name" class="block text-gray-700 mb-2 text-left input_label">
              You will receive an email when your import is complete
            </label>

          </div>
        </div>

        <div class="btn_line flex justify-end w-full px-6 modal_footer">
          <button
              @click="closeModal"
              class="this_button px-1 border">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadRoutesInfoModal",

  data: function () {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-upload-routes-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: 402;
}
.modal_header {
  border-radius: 8px 8px 0 0;
  border-bottom: none;
}
.modal_body {
  min-height: 100px;
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 546px;
  width: 50vw;
  margin: auto;
  height: 233px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  margin: 0 20px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.btn_line {
  border-radius: 0 0 8px 8px;
}
.input_label {
  font-weight: normal;
  font-size: 16px;
  span {
    color: #28a745;
  }
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
.bg_green {
  background-color: #1fbc2f;
}
</style>
